{
  "data": {
    "url": "https://xdc2j6a5wnbfdovqxkyayqnteu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_region": "us-east-1",
    "api_key": "da2-7mby73kwanf77jubpt75jdgioi",
    "default_authorization_type": "API_KEY",
    "authorization_types": [
      "AWS_LAMBDA",
      "AWS_IAM"
    ],
    "model_introspection": {
      "version": 1,
      "models": {
        "Restaurant": {
          "name": "Restaurant",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Restaurants",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "RestaurantMenuItem": {
          "name": "RestaurantMenuItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "basePriceCents": {
              "name": "basePriceCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "RestaurantMenuItemOption"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "menuItemId": {
              "name": "menuItemId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "menuItem": {
              "name": "menuItem",
              "isArray": false,
              "type": {
                "model": "MenuItem"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "menuItemId"
                ]
              }
            },
            "hasErrors": {
              "name": "hasErrors",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "errors": {
              "name": "errors",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "RestaurantMenuItems",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id",
                  "restaurantId",
                  "serviceMode",
                  "region"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "restaurantMenuItemsByRestaurantIdAndServiceModeAndRegion",
                "queryField": "listRestaurantMenuItemByRestaurantIdAndServiceModeAndRegion",
                "fields": [
                  "restaurantId",
                  "serviceMode",
                  "region"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": [
              "restaurantId",
              "serviceMode",
              "region"
            ]
          }
        },
        "MenuGuide": {
          "name": "MenuGuide",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "steps": {
              "name": "steps",
              "isArray": true,
              "type": {
                "nonModel": "MenuList"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "stepCount": {
              "name": "stepCount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuGuides",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "MenuItem": {
          "name": "MenuItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "menuPickerSlug": {
              "name": "menuPickerSlug",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "menuPicker": {
              "name": "menuPicker",
              "isArray": false,
              "type": {
                "model": "MenuPicker"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "menuPickerSlug"
                ]
              }
            },
            "restaurantMenuItems": {
              "name": "restaurantMenuItems",
              "isArray": true,
              "type": {
                "model": "RestaurantMenuItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "menuItemId"
                ]
              }
            },
            "content": {
              "name": "content",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemContent"
              },
              "isRequired": false,
              "attributes": []
            },
            "config": {
              "name": "config",
              "isArray": false,
              "type": {
                "model": "MenuItemConfig"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "HAS_ONE",
                "associatedWith": [
                  "menuItemId"
                ],
                "targetNames": []
              }
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemMetadata"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuItems",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "MenuItemConfig": {
          "name": "MenuItemConfig",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "menuItemId": {
              "name": "menuItemId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "menuItem": {
              "name": "menuItem",
              "isArray": false,
              "type": {
                "model": "MenuItem"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "menuItemId"
                ]
              }
            },
            "vendors": {
              "name": "vendors",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemConfigVendor"
              },
              "isRequired": true,
              "attributes": []
            },
            "loyalty": {
              "name": "loyalty",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemConfigLoyalty"
              },
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuItemConfigs",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "MenuPicker": {
          "name": "MenuPicker",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "badge": {
              "name": "badge",
              "isArray": false,
              "type": {
                "enum": "MenuItemBadge"
              },
              "isRequired": false,
              "attributes": []
            },
            "pickerAspect": {
              "name": "pickerAspect",
              "isArray": false,
              "type": {
                "nonModel": "MenuPickerAspect"
              },
              "isRequired": false,
              "attributes": []
            },
            "menuItems": {
              "name": "menuItems",
              "isArray": true,
              "type": {
                "model": "MenuItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "menuPickerSlug"
                ]
              }
            },
            "combo": {
              "name": "combo",
              "isArray": false,
              "type": {
                "nonModel": "MenuPickerCombo"
              },
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": {
                "nonModel": "MenuPickerMetadata"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuPickers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "slug"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "menuPickersById",
                "queryField": "listMenuPickerById",
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "slug",
            "sortKeyFieldNames": []
          }
        },
        "Menu": {
          "name": "Menu",
          "fields": {
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": {
                "nonModel": "MenuMetadata"
              },
              "isRequired": false,
              "attributes": []
            },
            "sections": {
              "name": "sections",
              "isArray": true,
              "type": {
                "nonModel": "MenuSection"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Menus",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "restaurantId",
                  "region",
                  "serviceMode"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "restaurantId",
            "sortKeyFieldNames": [
              "region",
              "serviceMode"
            ]
          }
        },
        "MenuSyncRecord": {
          "name": "MenuSyncRecord",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "log": {
              "name": "log",
              "isArray": false,
              "type": {
                "nonModel": "MenuSyncLog"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuSyncRecords",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list",
                      "create",
                      "update"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Cart": {
          "name": "Cart",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": {
                "enum": "CartState"
              },
              "isRequired": true,
              "attributes": []
            },
            "cartType": {
              "name": "cartType",
              "isArray": false,
              "type": {
                "enum": "CartType"
              },
              "isRequired": true,
              "attributes": []
            },
            "store": {
              "name": "store",
              "isArray": false,
              "type": {
                "nonModel": "CartStore"
              },
              "isRequired": false,
              "attributes": []
            },
            "version": {
              "name": "version",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "CartServiceMode"
              },
              "isRequired": true,
              "attributes": []
            },
            "appliedIncentives": {
              "name": "appliedIncentives",
              "isArray": true,
              "type": {
                "nonModel": "CartIncentive"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "entries": {
              "name": "entries",
              "isArray": true,
              "type": {
                "nonModel": "CartItemEntry"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "delivery": {
              "name": "delivery",
              "isArray": false,
              "type": {
                "nonModel": "DeliveryCartRecord"
              },
              "isRequired": false,
              "attributes": []
            },
            "donationAmount": {
              "name": "donationAmount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "rbiOrderId": {
              "name": "rbiOrderId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": {
                "nonModel": "CartMetaData"
              },
              "isRequired": true,
              "attributes": []
            },
            "deleteItemAt": {
              "name": "deleteItemAt",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Carts",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "name": "cartsByUserIdAndCartType",
                "queryField": "listCartByUserIdAndCartType",
                "fields": [
                  "userId",
                  "cartType"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "cartsByRbiOrderIdAndCartType",
                "queryField": "listCartByRbiOrderIdAndCartType",
                "fields": [
                  "rbiOrderId",
                  "cartType"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "custom",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Order": {
          "name": "Order",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "store": {
              "name": "store",
              "isArray": false,
              "type": {
                "nonModel": "CartStore"
              },
              "isRequired": false,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "CartServiceMode"
              },
              "isRequired": true,
              "attributes": []
            },
            "entries": {
              "name": "entries",
              "isArray": true,
              "type": {
                "nonModel": "CartItemEntry"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "donationAmount": {
              "name": "donationAmount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "orderTime": {
              "name": "orderTime",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "pricingData": {
              "name": "pricingData",
              "isArray": false,
              "type": {
                "nonModel": "OrderPricingData"
              },
              "isRequired": false,
              "attributes": []
            },
            "appliedIncentives": {
              "name": "appliedIncentives",
              "isArray": true,
              "type": {
                "nonModel": "CartIncentive"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Orders",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "custom",
                    "operations": [
                      "create",
                      "get"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        }
      },
      "enums": {
        "DeliveryStatus": {
          "name": "DeliveryStatus",
          "values": [
            "DRIVER_ASSIGNED",
            "DRIVER_AT_CUSTOMER",
            "DRIVER_AT_STORE",
            "DRIVER_STARTING",
            "DRIVER_UNASSIGNED",
            "ORDER_ABANDONED",
            "ORDER_CANCELLED",
            "ORDER_CREATED",
            "ORDER_DROPPED_OFF",
            "ORDER_ERROR",
            "ORDER_PICKED_UP",
            "ORDER_TIMEOUT",
            "QUOTE_ERROR",
            "QUOTE_REQUESTED",
            "QUOTE_SUCCESSFUL",
            "QUOTE_UNAVAILABLE"
          ]
        },
        "DeliveryStoreStatus": {
          "name": "DeliveryStoreStatus",
          "values": [
            "OPEN",
            "CLOSED",
            "NO_DELIVERY"
          ]
        },
        "MenuItemBadge": {
          "name": "MenuItemBadge",
          "values": [
            "NONE",
            "NEW",
            "LIMITED",
            "OUT_OF_STOCK"
          ]
        },
        "OfferIncentiveType": {
          "name": "OfferIncentiveType",
          "values": [
            "BOGO_DISCOUNT",
            "PRICE_POINT_DISCOUNT",
            "GLOBAL_DISCOUNT",
            "ITEM_LEVEL_DISCOUNT",
            "BUNDLE_DISCOUNT",
            "STEPPER_DISCOUNT",
            "FEES_DISCOUNT",
            "BOGO_DYNAMIC_DISCOUNT"
          ]
        },
        "CartState": {
          "name": "CartState",
          "values": [
            "VALID",
            "NEEDS_LOYALTY_VALIDATION",
            "LOYALTY_INVALID",
            "PRICE_REQUESTED",
            "PRICE_SUCCESSFUL",
            "PRICE_ERROR",
            "INSERT_REQUESTED",
            "INSERT_ERROR",
            "INSERT_SUCCESSFUL"
          ]
        },
        "CartType": {
          "name": "CartType",
          "values": [
            "USER_CART",
            "GUEST_CART"
          ]
        },
        "CartServiceMode": {
          "name": "CartServiceMode",
          "values": [
            "CATERING_PICKUP",
            "CATERING_DELIVERY",
            "CURBSIDE",
            "DELIVERY",
            "DRIVE_THRU",
            "EAT_IN",
            "TAKEOUT",
            "PICKUP_WINDOW"
          ]
        },
        "LegacyCartItemType": {
          "name": "LegacyCartItemType",
          "values": [
            "Combo",
            "ComboSlot",
            "Item",
            "ItemOption",
            "ItemOptionModifier",
            "OfferCombo",
            "OfferDiscount",
            "OfferItem"
          ]
        },
        "MenuGuideRegion": {
          "name": "MenuGuideRegion",
          "values": [
            "us",
            "ca"
          ]
        },
        "MenuGuideServiceMode": {
          "name": "MenuGuideServiceMode",
          "values": [
            "pickup",
            "delivery"
          ]
        },
        "ConvertMenuItemFormatServiceMode": {
          "name": "ConvertMenuItemFormatServiceMode",
          "values": [
            "CATERING_PICKUP",
            "CATERING_DELIVERY",
            "CURBSIDE",
            "DELIVERY",
            "DRIVE_THRU",
            "EAT_IN",
            "TAKEOUT",
            "PICKUP_WINDOW"
          ]
        },
        "ConvertToMenuItemServiceMode": {
          "name": "ConvertToMenuItemServiceMode",
          "values": [
            "CATERING_PICKUP",
            "CATERING_DELIVERY",
            "CURBSIDE",
            "DELIVERY",
            "DRIVE_THRU",
            "EAT_IN",
            "TAKEOUT",
            "PICKUP_WINDOW"
          ]
        },
        "SelectStoreServiceMode": {
          "name": "SelectStoreServiceMode",
          "values": [
            "CATERING_PICKUP",
            "CATERING_DELIVERY",
            "CURBSIDE",
            "DELIVERY",
            "DRIVE_THRU",
            "EAT_IN",
            "TAKEOUT",
            "PICKUP_WINDOW"
          ]
        },
        "MenuItemCustomizationOptionDisplayType": {
          "name": "MenuItemCustomizationOptionDisplayType",
          "values": [
            "SELECT",
            "CHECKBOX",
            "STEPPER",
            "QUANTITY",
            "RADIO"
          ]
        },
        "MenuPickerAspectDisplayType": {
          "name": "MenuPickerAspectDisplayType",
          "values": [
            "SIZE",
            "SELECT",
            "ITEM"
          ]
        },
        "MenuSyncLogSyncType": {
          "name": "MenuSyncLogSyncType",
          "values": [
            "CONTENT",
            "POS"
          ]
        },
        "MenuSyncLogErrorType": {
          "name": "MenuSyncLogErrorType",
          "values": [
            "CONTENT",
            "POS",
            "NETWORK",
            "UNKNOWN",
            "AWS"
          ]
        },
        "LegacyCartVendorConfigPluType": {
          "name": "LegacyCartVendorConfigPluType",
          "values": [
            "ignore",
            "constantPlu"
          ]
        },
        "StoredPaymentMethodBrand": {
          "name": "StoredPaymentMethodBrand",
          "values": [
            "VISA",
            "AMEX",
            "DISCOVER",
            "DINERS_CLUB",
            "JCB",
            "MASTERCARD"
          ]
        },
        "StoredPaymentMethodType": {
          "name": "StoredPaymentMethodType",
          "values": [
            "SCHEME"
          ]
        },
        "UserPaymentInformationPaymentConfigFrontendIntegration": {
          "name": "UserPaymentInformationPaymentConfigFrontendIntegration",
          "values": [
            "WORLDPAY",
            "CHASE_ORBITAL"
          ]
        }
      },
      "nonModels": {
        "Image": {
          "name": "Image",
          "fields": {
            "altText": {
              "name": "altText",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "asset": {
              "name": "asset",
              "isArray": false,
              "type": {
                "nonModel": "ImageAsset"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Address": {
          "name": "Address",
          "fields": {
            "addressLine1": {
              "name": "addressLine1",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "addressLine2": {
              "name": "addressLine2",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "latitude": {
              "name": "latitude",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "longitude": {
              "name": "longitude",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "zip": {
              "name": "zip",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "deliveryInformation": {
              "name": "deliveryInformation",
              "isArray": false,
              "type": {
                "nonModel": "DeliveryInformation"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "DeliveryCartRecord": {
          "name": "DeliveryCartRecord",
          "fields": {
            "quoteId": {
              "name": "quoteId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "dropoffAddress": {
              "name": "dropoffAddress",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": true,
              "attributes": []
            },
            "pickupAddress": {
              "name": "pickupAddress",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "DeliveryInformation": {
          "name": "DeliveryInformation",
          "fields": {
            "estimatedTimeWindow": {
              "name": "estimatedTimeWindow",
              "isArray": false,
              "type": {
                "nonModel": "DeliveryTimeWindow"
              },
              "isRequired": false,
              "attributes": []
            },
            "arrivalTime": {
              "name": "arrivalTime",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "DeliveryTimeWindow": {
          "name": "DeliveryTimeWindow",
          "fields": {
            "beforeTime": {
              "name": "beforeTime",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "afterTime": {
              "name": "afterTime",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "DeliveryQuoteResponse": {
          "name": "DeliveryQuoteResponse",
          "fields": {
            "storeStatus": {
              "name": "storeStatus",
              "isArray": false,
              "type": {
                "enum": "DeliveryStoreStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "quote": {
              "name": "quote",
              "isArray": false,
              "type": {
                "enum": "DeliveryStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "nextEarliestOpen": {
              "name": "nextEarliestOpen",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "RestaurantMenuItemOption": {
          "name": "RestaurantMenuItemOption",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "upChargeCents": {
              "name": "upChargeCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "MenuList": {
          "name": "MenuList",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            },
            "sections": {
              "name": "sections",
              "isArray": true,
              "type": {
                "nonModel": "MenuSection"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "shouldShowSections": {
              "name": "shouldShowSections",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "preSelectedItemId": {
              "name": "preSelectedItemId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemContent": {
          "name": "MenuItemContent",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "isVisible": {
              "name": "isVisible",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "menuPickerSlug": {
              "name": "menuPickerSlug",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "baseCalories": {
              "name": "baseCalories",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "badge": {
              "name": "badge",
              "isArray": false,
              "type": {
                "enum": "MenuItemBadge"
              },
              "isRequired": false,
              "attributes": []
            },
            "basePriceCents": {
              "name": "basePriceCents",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "nutrition": {
              "name": "nutrition",
              "isArray": true,
              "type": {
                "nonModel": "MenuItemNutrition"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "customizations": {
              "name": "customizations",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemCustomizations"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemNutrition": {
          "name": "MenuItemNutrition",
          "fields": {
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "units": {
              "name": "units",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayValue": {
              "name": "displayValue",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "MenuItemConfigVendor": {
          "name": "MenuItemConfigVendor",
          "fields": {
            "ncr": {
              "name": "ncr",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "toast": {
              "name": "toast",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemConfigLoyalty": {
          "name": "MenuItemConfigLoyalty",
          "fields": {
            "points": {
              "name": "points",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemCustomizations": {
          "name": "MenuItemCustomizations",
          "fields": {
            "displayGroups": {
              "name": "displayGroups",
              "isArray": true,
              "type": {
                "nonModel": "MenuItemCustomizationOptionGroup"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuItemCustomizationOptionGroup": {
          "name": "MenuItemCustomizationOptionGroup",
          "fields": {
            "displayGroup": {
              "name": "displayGroup",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemCustomizationDisplayGroup"
              },
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "MenuItemCustomizationOption"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuItemCustomizationDisplayGroup": {
          "name": "MenuItemCustomizationDisplayGroup",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayRank": {
              "name": "displayRank",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "showOnProductPage": {
              "name": "showOnProductPage",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemCustomizationOption": {
          "name": "MenuItemCustomizationOption",
          "fields": {
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayType": {
              "name": "displayType",
              "isArray": false,
              "type": {
                "enum": "MenuItemCustomizationOptionDisplayType"
              },
              "isRequired": false,
              "attributes": []
            },
            "minAmount": {
              "name": "minAmount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "maxAmount": {
              "name": "maxAmount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "defaultOptionKey": {
              "name": "defaultOptionKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "MenuItemCustomizationOptionValue"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuItemCustomizationOptionValue": {
          "name": "MenuItemCustomizationOptionValue",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "prefix": {
              "name": "prefix",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "multiplier": {
              "name": "multiplier",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            },
            "caloriesOffset": {
              "name": "caloriesOffset",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "isDefault": {
              "name": "isDefault",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "upChargeCents": {
              "name": "upChargeCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "MenuPickerCombo": {
          "name": "MenuPickerCombo",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "basePriceCents": {
              "name": "basePriceCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "isRequired": {
              "name": "isRequired",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "slots": {
              "name": "slots",
              "isArray": true,
              "type": {
                "nonModel": "MenuPickerComboSlot"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuPickerComboSlot": {
          "name": "MenuPickerComboSlot",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isRequired": {
              "name": "isRequired",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuPickerAspect": {
          "name": "MenuPickerAspect",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "displayType": {
              "name": "displayType",
              "isArray": false,
              "type": {
                "enum": "MenuPickerAspectDisplayType"
              },
              "isRequired": false,
              "attributes": []
            },
            "defaultOptionKey": {
              "name": "defaultOptionKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "MenuPickerAspectOption"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuPickerAspectOption": {
          "name": "MenuPickerAspectOption",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            },
            "isDefault": {
              "name": "isDefault",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "item": {
              "name": "item",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemContent"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuSection": {
          "name": "MenuSection",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "descriptionShort": {
              "name": "descriptionShort",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "items": {
              "name": "items",
              "isArray": true,
              "type": {
                "nonModel": "MenuSectionItem"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuSectionItem": {
          "name": "MenuSectionItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "descriptionShort": {
              "name": "descriptionShort",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "badge": {
              "name": "badge",
              "isArray": false,
              "type": {
                "enum": "MenuItemBadge"
              },
              "isRequired": false,
              "attributes": []
            },
            "_legacySlug": {
              "name": "_legacySlug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "_legacyUrl": {
              "name": "_legacyUrl",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "requiresCustomization": {
              "name": "requiresCustomization",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "priceCents": {
              "name": "priceCents",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "baseCalories": {
              "name": "baseCalories",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuSyncLog": {
          "name": "MenuSyncLog",
          "fields": {
            "syncType": {
              "name": "syncType",
              "isArray": false,
              "type": {
                "enum": "MenuSyncLogSyncType"
              },
              "isRequired": false,
              "attributes": []
            },
            "requestInfo": {
              "name": "requestInfo",
              "isArray": false,
              "type": {
                "nonModel": "MenuSyncLogRequestInfo"
              },
              "isRequired": false,
              "attributes": []
            },
            "durationTotalMs": {
              "name": "durationTotalMs",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "count": {
              "name": "count",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "errors": {
              "name": "errors",
              "isArray": true,
              "type": {
                "nonModel": "MenuSyncLogError"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "MenuSyncLogError": {
          "name": "MenuSyncLogError",
          "fields": {
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "MenuSyncLogErrorType"
              },
              "isRequired": false,
              "attributes": []
            },
            "error": {
              "name": "error",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "syncAllRestaurantMenuResp": {
          "name": "syncAllRestaurantMenuResp",
          "fields": {
            "success": {
              "name": "success",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "error": {
              "name": "error",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CartEntryV1": {
          "name": "CartEntryV1",
          "fields": {
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "sanityId": {
              "name": "sanityId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isDonation": {
              "name": "isDonation",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "children": {
              "name": "children",
              "isArray": true,
              "type": {
                "nonModel": "CartEntryV1"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "Product": {
          "name": "Product",
          "fields": {
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "FixedPrice": {
          "name": "FixedPrice",
          "fields": {
            "groups": {
              "name": "groups",
              "isArray": true,
              "type": {
                "nonModel": "PriceGroup"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "defaultPrice": {
              "name": "defaultPrice",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "TierPricing": {
          "name": "TierPricing",
          "fields": {
            "products": {
              "name": "products",
              "isArray": true,
              "type": {
                "nonModel": "Product"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": {
                "nonModel": "FixedPrice"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PriceGroup": {
          "name": "PriceGroup",
          "fields": {
            "restaurants": {
              "name": "restaurants",
              "isArray": true,
              "type": "Int",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PricingStrategy": {
          "name": "PricingStrategy",
          "fields": {
            "pricingStrategy": {
              "name": "pricingStrategy",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "maxPriceToDiscount": {
              "name": "maxPriceToDiscount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "maxItemQuantityDiscount": {
              "name": "maxItemQuantityDiscount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "groups": {
              "name": "groups",
              "isArray": true,
              "type": {
                "nonModel": "PriceGroup"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "defaultPrice": {
              "name": "defaultPrice",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "tier": {
              "name": "tier",
              "isArray": true,
              "type": {
                "nonModel": "TierPricing"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "Tier": {
          "name": "Tier",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "steps": {
              "name": "steps",
              "isArray": true,
              "type": {
                "nonModel": "Step"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Step": {
          "name": "Step",
          "fields": {
            "eligibleItems": {
              "name": "eligibleItems",
              "isArray": true,
              "type": {
                "nonModel": "MenuSectionItem"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "preSelectedItem": {
              "name": "preSelectedItem",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "rank": {
              "name": "rank",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "subtitle": {
              "name": "subtitle",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "BaseOffer": {
          "name": "BaseOffer",
          "fields": {
            "cmsId": {
              "name": "cmsId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "expiryDate": {
              "name": "expiryDate",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "termsAndConditions": {
              "name": "termsAndConditions",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "incentiveType": {
              "name": "incentiveType",
              "isArray": false,
              "type": {
                "enum": "OfferIncentiveType"
              },
              "isRequired": true,
              "attributes": []
            },
            "pricingStrategy": {
              "name": "pricingStrategy",
              "isArray": false,
              "type": {
                "nonModel": "PricingStrategy"
              },
              "isRequired": false,
              "attributes": []
            },
            "tag": {
              "name": "tag",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "errors": {
              "name": "errors",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "discountFees": {
              "name": "discountFees",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "requireGuideFlow": {
              "name": "requireGuideFlow",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "requireTierSelector": {
              "name": "requireTierSelector",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "OfferListItem": {
          "name": "OfferListItem",
          "fields": {
            "cmsId": {
              "name": "cmsId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "expiryDate": {
              "name": "expiryDate",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "termsAndConditions": {
              "name": "termsAndConditions",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "incentiveType": {
              "name": "incentiveType",
              "isArray": false,
              "type": {
                "enum": "OfferIncentiveType"
              },
              "isRequired": true,
              "attributes": []
            },
            "pricingStrategy": {
              "name": "pricingStrategy",
              "isArray": false,
              "type": {
                "nonModel": "PricingStrategy"
              },
              "isRequired": false,
              "attributes": []
            },
            "tag": {
              "name": "tag",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "errors": {
              "name": "errors",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "discountFees": {
              "name": "discountFees",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "requireGuideFlow": {
              "name": "requireGuideFlow",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "requireTierSelector": {
              "name": "requireTierSelector",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "cardDescription": {
              "name": "cardDescription",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "OfferDetail": {
          "name": "OfferDetail",
          "fields": {
            "cmsId": {
              "name": "cmsId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "expiryDate": {
              "name": "expiryDate",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "termsAndConditions": {
              "name": "termsAndConditions",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "incentiveType": {
              "name": "incentiveType",
              "isArray": false,
              "type": {
                "enum": "OfferIncentiveType"
              },
              "isRequired": true,
              "attributes": []
            },
            "pricingStrategy": {
              "name": "pricingStrategy",
              "isArray": false,
              "type": {
                "nonModel": "PricingStrategy"
              },
              "isRequired": false,
              "attributes": []
            },
            "tag": {
              "name": "tag",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "errors": {
              "name": "errors",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "discountFees": {
              "name": "discountFees",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "requireGuideFlow": {
              "name": "requireGuideFlow",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "requireTierSelector": {
              "name": "requireTierSelector",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "steps": {
              "name": "steps",
              "isArray": true,
              "type": {
                "nonModel": "Step"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "tiers": {
              "name": "tiers",
              "isArray": true,
              "type": {
                "nonModel": "Tier"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "Reward": {
          "name": "Reward",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "points": {
              "name": "points",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LoyaltyIncentives": {
          "name": "LoyaltyIncentives",
          "fields": {
            "offers": {
              "name": "offers",
              "isArray": true,
              "type": {
                "nonModel": "OfferListItem"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "rewards": {
              "name": "rewards",
              "isArray": true,
              "type": {
                "nonModel": "Reward"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "CartStore": {
          "name": "CartStore",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "posVendor": {
              "name": "posVendor",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "posRestaurantId": {
              "name": "posRestaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "latitude": {
              "name": "latitude",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "longitude": {
              "name": "longitude",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "addressLine1": {
              "name": "addressLine1",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "addressLine2": {
              "name": "addressLine2",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "zip": {
              "name": "zip",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartItemEntryModifiers": {
          "name": "CartItemEntryModifiers",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartIncentiveError": {
          "name": "CartIncentiveError",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CartIncentive": {
          "name": "CartIncentive",
          "fields": {
            "ruleErrors": {
              "name": "ruleErrors",
              "isArray": true,
              "type": {
                "nonModel": "CartIncentiveError"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "benefitErrors": {
              "name": "benefitErrors",
              "isArray": true,
              "type": {
                "nonModel": "CartIncentiveError"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "savingsAmount": {
              "name": "savingsAmount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "offerId": {
              "name": "offerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "appliedCartEntries": {
              "name": "appliedCartEntries",
              "isArray": true,
              "type": "String",
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartFeeEntry": {
          "name": "CartFeeEntry",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "totalValueCents": {
              "name": "totalValueCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartItemEntry": {
          "name": "CartItemEntry",
          "fields": {
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "instructions": {
              "name": "instructions",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "modifiers": {
              "name": "modifiers",
              "isArray": true,
              "type": {
                "nonModel": "CartItemEntryModifiers"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            },
            "pointCost": {
              "name": "pointCost",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartTaxEntry": {
          "name": "CartTaxEntry",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "totalCents": {
              "name": "totalCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CartMetaData": {
          "name": "CartMetaData",
          "fields": {
            "pricingData": {
              "name": "pricingData",
              "isArray": false,
              "type": {
                "nonModel": "CartMetaDataPricingData"
              },
              "isRequired": false,
              "attributes": []
            },
            "userSelectedOrderTime": {
              "name": "userSelectedOrderTime",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LegacyCartVendorConfig": {
          "name": "LegacyCartVendorConfig",
          "fields": {
            "pluType": {
              "name": "pluType",
              "isArray": false,
              "type": {
                "enum": "LegacyCartVendorConfigPluType"
              },
              "isRequired": false,
              "attributes": []
            },
            "constantPlu": {
              "name": "constantPlu",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LegacyCartVendorConfigs": {
          "name": "LegacyCartVendorConfigs",
          "fields": {
            "ncr": {
              "name": "ncr",
              "isArray": false,
              "type": {
                "nonModel": "LegacyCartVendorConfig"
              },
              "isRequired": false,
              "attributes": []
            },
            "ncrDelivery": {
              "name": "ncrDelivery",
              "isArray": false,
              "type": {
                "nonModel": "LegacyCartVendorConfig"
              },
              "isRequired": false,
              "attributes": []
            },
            "toast": {
              "name": "toast",
              "isArray": false,
              "type": {
                "nonModel": "LegacyCartVendorConfig"
              },
              "isRequired": false,
              "attributes": []
            },
            "toastDelivery": {
              "name": "toastDelivery",
              "isArray": false,
              "type": {
                "nonModel": "LegacyCartVendorConfig"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LegacyCartItem": {
          "name": "LegacyCartItem",
          "fields": {
            "cartId": {
              "name": "cartId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "children": {
              "name": "children",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            },
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "pickerSelections": {
              "name": "pickerSelections",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "sanityId": {
              "name": "sanityId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isExtra": {
              "name": "isExtra",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "isDonation": {
              "name": "isDonation",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "vendorConfigs": {
              "name": "vendorConfigs",
              "isArray": false,
              "type": {
                "nonModel": "LegacyCartVendorConfigs"
              },
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "LegacyCartItemType"
              },
              "isRequired": true,
              "attributes": []
            },
            "_id": {
              "name": "_id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "LegacyCartMenuConversionResult": {
          "name": "LegacyCartMenuConversionResult",
          "fields": {
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "modifiersJson": {
              "name": "modifiersJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "isCombo": {
              "name": "isCombo",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "pickerSelections": {
              "name": "pickerSelections",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "storeId": {
              "name": "storeId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "CartServiceMode"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "timeslot": {
          "name": "timeslot",
          "fields": {
            "time": {
              "name": "time",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "dayslot": {
          "name": "dayslot",
          "fields": {
            "date": {
              "name": "date",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": true,
              "attributes": []
            },
            "warningMessage": {
              "name": "warningMessage",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isDisabled": {
              "name": "isDisabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "timeslot"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "storedPaymentMethod": {
          "name": "storedPaymentMethod",
          "fields": {
            "fullName": {
              "name": "fullName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "brand": {
              "name": "brand",
              "isArray": false,
              "type": {
                "enum": "StoredPaymentMethodBrand"
              },
              "isRequired": false,
              "attributes": []
            },
            "expiryYear": {
              "name": "expiryYear",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "expiryMonth": {
              "name": "expiryMonth",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "panToken": {
              "name": "panToken",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "StoredPaymentMethodType"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "userPaymentInformation": {
          "name": "userPaymentInformation",
          "fields": {
            "paymentConfig": {
              "name": "paymentConfig",
              "isArray": false,
              "type": {
                "nonModel": "UserPaymentInformationPaymentConfig"
              },
              "isRequired": false,
              "attributes": []
            },
            "paymentPreferences": {
              "name": "paymentPreferences",
              "isArray": false,
              "type": {
                "nonModel": "UserPaymentInformationPaymentPreferences"
              },
              "isRequired": false,
              "attributes": []
            },
            "storedPaymentMethods": {
              "name": "storedPaymentMethods",
              "isArray": true,
              "type": {
                "nonModel": "storedPaymentMethod"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "EventInvocationResponse": {
          "name": "EventInvocationResponse",
          "fields": {
            "success": {
              "name": "success",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "MenuItemMetadata": {
          "name": "MenuItemMetadata",
          "fields": {
            "availabilityLastUpdatedAt": {
              "name": "availabilityLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "menuLastUpdatedAt": {
              "name": "menuLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "processingTimeMs": {
              "name": "processingTimeMs",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "isCacheHit": {
              "name": "isCacheHit",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuPickerMetadata": {
          "name": "MenuPickerMetadata",
          "fields": {
            "availabilityLastUpdatedAt": {
              "name": "availabilityLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "menuLastUpdatedAt": {
              "name": "menuLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "processingTimeMs": {
              "name": "processingTimeMs",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "isCacheHit": {
              "name": "isCacheHit",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuMetadata": {
          "name": "MenuMetadata",
          "fields": {
            "availabilityLastUpdatedAt": {
              "name": "availabilityLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "menuLastUpdatedAt": {
              "name": "menuLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "processingTimeMs": {
              "name": "processingTimeMs",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "isCacheHit": {
              "name": "isCacheHit",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "OrderPricingData": {
          "name": "OrderPricingData",
          "fields": {
            "subTotalCents": {
              "name": "subTotalCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "totalCents": {
              "name": "totalCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "taxCents": {
              "name": "taxCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "ImageAsset": {
          "name": "ImageAsset",
          "fields": {
            "uri": {
              "name": "uri",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": true,
              "attributes": []
            },
            "blurHash": {
              "name": "blurHash",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuSyncLogRequestInfo": {
          "name": "MenuSyncLogRequestInfo",
          "fields": {
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CartMetaDataPricingData": {
          "name": "CartMetaDataPricingData",
          "fields": {
            "subTotalCents": {
              "name": "subTotalCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "totalCents": {
              "name": "totalCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "taxCents": {
              "name": "taxCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fees": {
              "name": "fees",
              "isArray": true,
              "type": {
                "nonModel": "CartFeeEntry"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "UserPaymentInformationPaymentConfig": {
          "name": "UserPaymentInformationPaymentConfig",
          "fields": {
            "frontendIntegration": {
              "name": "frontendIntegration",
              "isArray": false,
              "type": {
                "enum": "UserPaymentInformationPaymentConfigFrontendIntegration"
              },
              "isRequired": false,
              "attributes": []
            },
            "applePay": {
              "name": "applePay",
              "isArray": false,
              "type": {
                "nonModel": "UserPaymentInformationPaymentConfigApplePay"
              },
              "isRequired": false,
              "attributes": []
            },
            "googlePay": {
              "name": "googlePay",
              "isArray": false,
              "type": {
                "nonModel": "UserPaymentInformationPaymentConfigGooglePay"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "UserPaymentInformationPaymentPreferences": {
          "name": "UserPaymentInformationPaymentPreferences",
          "fields": {
            "storedPaymentMethodId": {
              "name": "storedPaymentMethodId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "UserPaymentInformationPaymentConfigApplePay": {
          "name": "UserPaymentInformationPaymentConfigApplePay",
          "fields": {
            "schemeMerchantId": {
              "name": "schemeMerchantId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "prepaidMerchantId": {
              "name": "prepaidMerchantId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "UserPaymentInformationPaymentConfigGooglePay": {
          "name": "UserPaymentInformationPaymentConfigGooglePay",
          "fields": {
            "schemeMerchantId": {
              "name": "schemeMerchantId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "prepaidMerchantId": {
              "name": "prepaidMerchantId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        }
      },
      "queries": {
        "menuGuide": {
          "name": "menuGuide",
          "isArray": false,
          "type": {
            "model": "MenuGuide"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": {
                "enum": "MenuGuideRegion"
              },
              "isRequired": false
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "MenuGuideServiceMode"
              },
              "isRequired": false
            }
          }
        },
        "getMenuItemForRestaurant": {
          "name": "getMenuItemForRestaurant",
          "isArray": false,
          "type": {
            "model": "MenuItem"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getMenuPickerForRestaurant": {
          "name": "getMenuPickerForRestaurant",
          "isArray": false,
          "type": {
            "model": "MenuPicker"
          },
          "isRequired": false,
          "arguments": {
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getMenuSectionsForRestaurant": {
          "name": "getMenuSectionsForRestaurant",
          "isArray": false,
          "type": {
            "model": "Menu"
          },
          "isRequired": false,
          "arguments": {
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getLoyaltyIncentives": {
          "name": "getLoyaltyIncentives",
          "isArray": false,
          "type": {
            "nonModel": "LoyaltyIncentives"
          },
          "isRequired": false,
          "arguments": {
            "storeNumber": {
              "name": "storeNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "loyaltyId": {
              "name": "loyaltyId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            }
          }
        },
        "getOfferDetailUi": {
          "name": "getOfferDetailUi",
          "isArray": false,
          "type": {
            "nonModel": "OfferDetail"
          },
          "isRequired": false,
          "arguments": {
            "cartEntriesV1": {
              "name": "cartEntriesV1",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            },
            "offerId": {
              "name": "offerId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "storeNumber": {
              "name": "storeNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "loyaltyId": {
              "name": "loyaltyId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false
            }
          }
        },
        "cart": {
          "name": "cart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false
        },
        "convertMenuItemFormat": {
          "name": "convertMenuItemFormat",
          "isArray": false,
          "type": {
            "nonModel": "LegacyCartItem"
          },
          "isRequired": false,
          "arguments": {
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": true
            },
            "modifiersJson": {
              "name": "modifiersJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            },
            "isCombo": {
              "name": "isCombo",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true
            },
            "pickerSelections": {
              "name": "pickerSelections",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            },
            "storeId": {
              "name": "storeId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "ConvertMenuItemFormatServiceMode"
              },
              "isRequired": false
            }
          }
        },
        "convertToMenuItem": {
          "name": "convertToMenuItem",
          "isArray": false,
          "type": {
            "nonModel": "LegacyCartMenuConversionResult"
          },
          "isRequired": false,
          "arguments": {
            "cartEntry": {
              "name": "cartEntry",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            },
            "storeId": {
              "name": "storeId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "ConvertToMenuItemServiceMode"
              },
              "isRequired": false
            }
          }
        },
        "orderTimeslots": {
          "name": "orderTimeslots",
          "isArray": true,
          "type": {
            "nonModel": "dayslot"
          },
          "isRequired": false,
          "isArrayNullable": false
        },
        "order": {
          "name": "order",
          "isArray": false,
          "type": {
            "model": "Order"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "legacyUserPaymentInformation": {
          "name": "legacyUserPaymentInformation",
          "isArray": false,
          "type": {
            "nonModel": "userPaymentInformation"
          },
          "isRequired": false
        }
      },
      "mutations": {
        "setDeliveryRestaurant": {
          "name": "setDeliveryRestaurant",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "storeNumber": {
              "name": "storeNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "deliveryAddress": {
              "name": "deliveryAddress",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            },
            "quoteId": {
              "name": "quoteId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "quoteDeliveryOrder": {
          "name": "quoteDeliveryOrder",
          "isArray": false,
          "type": {
            "nonModel": "DeliveryQuoteResponse"
          },
          "isRequired": false,
          "arguments": {
            "deliveryAddress": {
              "name": "deliveryAddress",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true
            }
          }
        },
        "syncAllRestaurantMenu": {
          "name": "syncAllRestaurantMenu",
          "isArray": false,
          "type": {
            "nonModel": "syncAllRestaurantMenuResp"
          },
          "isRequired": false,
          "arguments": {
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "syncMenuContent": {
          "name": "syncMenuContent",
          "isArray": false,
          "type": {
            "nonModel": "EventInvocationResponse"
          },
          "isRequired": false,
          "arguments": {
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "syncRestaurantMenu": {
          "name": "syncRestaurantMenu",
          "isArray": false,
          "type": {
            "nonModel": "EventInvocationResponse"
          },
          "isRequired": false,
          "arguments": {
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "addItemToCart": {
          "name": "addItemToCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            },
            "instructions": {
              "name": "instructions",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false
            },
            "modifiersJson": {
              "name": "modifiersJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            },
            "comboSlotsJson": {
              "name": "comboSlotsJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            },
            "isCombo": {
              "name": "isCombo",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false
            }
          }
        },
        "setOrderFulfillmentTime": {
          "name": "setOrderFulfillmentTime",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "fulfillmentTime": {
              "name": "fulfillmentTime",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "setDonationAmount": {
          "name": "setDonationAmount",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true
            }
          }
        },
        "addOfferToCart": {
          "name": "addOfferToCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "offerId": {
              "name": "offerId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            },
            "entriesJson": {
              "name": "entriesJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            }
          }
        },
        "removeOfferFromCart": {
          "name": "removeOfferFromCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "offerId": {
              "name": "offerId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "updateItemInCart": {
          "name": "updateItemInCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            },
            "itemId": {
              "name": "itemId",
              "isArray": false,
              "type": "ID",
              "isRequired": false
            },
            "instructions": {
              "name": "instructions",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false
            },
            "modifiersJson": {
              "name": "modifiersJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            },
            "comboSlotsJson": {
              "name": "comboSlotsJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            },
            "isCombo": {
              "name": "isCombo",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false
            }
          }
        },
        "priceCart": {
          "name": "priceCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false
        },
        "commitCart": {
          "name": "commitCart",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "paymentJson": {
              "name": "paymentJson",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false
            }
          }
        },
        "selectStore": {
          "name": "selectStore",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "storeNumber": {
              "name": "storeNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": {
                "enum": "SelectStoreServiceMode"
              },
              "isRequired": false
            }
          }
        },
        "usePointsOnItem": {
          "name": "usePointsOnItem",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "removePointUsageFromItem": {
          "name": "removePointUsageFromItem",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false,
          "arguments": {
            "lineId": {
              "name": "lineId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        }
      },
      "subscriptions": {
        "cartUpdates": {
          "name": "cartUpdates",
          "isArray": false,
          "type": {
            "model": "Cart"
          },
          "isRequired": false
        }
      }
    }
  },
  "version": "1.3",
  "custom": {
    "API": {
      "menu-update-webhook-api": {
        "menu-update-webhook-api-id": "5zix542k6d",
        "menu-update-webhook-api-endpoint": "https://5zix542k6d.execute-api.us-east-1.amazonaws.com/",
        "menu-update-webhook-api-name": "menu-update-webhook-api",
        "menu-update-webhook-api-region": "us-east-1"
      }
    }
  }
}